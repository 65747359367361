import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { useEffect, useRef, useState } from "react";
import { getActivityId, storeActivityIdInSessionStorage, timeoutPromise } from "../helpers";
import { datadogRum } from "@datadog/browser-rum";
import { EventNames, TrackingEventTypes, useAnalytics } from "@cinch-nx/shared-analytics";
/**
 * Custom hook for retrieving Adobe Target activity data.
 *
 * @param activityConst - The constant representing the activity.
 * @param shouldFlagActivity - A flag to send an additional event to adobe when the experiment was found. One event per session per activity will be fired.
 * @param targetTimeout - The timeout duration for retrieving the activity data (default: 3000).
 * @returns The activity data or null if not found.
 * @example
 *  const experimentData = useAdobeTarget('EXAMPLE_ACTIVITY')
 *  const experimentIsActive = activityData?.ActivityState === 'Variant'
 *
 *  if (experimentIsActive) {
 *  // Do something
 *  }
 */ export function useAdobeTarget(activityConst) {
    var shouldFlagActivity = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, targetTimeout = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 5000;
    var trackEvent = useAnalytics().trackEvent;
    var activityId = getActivityId(activityConst);
    var ref = useState(function() {
        var ref;
        if ((ref = getTargetPropertyFromWindow()) === null || ref === void 0 ? void 0 : ref.length) {
            return getTargetActivity(activityId);
        }
        return;
    }), activityData = ref[0], setActivityData = ref[1];
    var didCancel = useRef(false);
    var setActivityDataIfMounted = function(value) {
        if (didCancel.current === true) {
            return;
        }
        setActivityData(value);
    };
    var handleTtMetaTracking = function(activityId, experienceId) {
        var ttMetaTrackingActivityIdString = sessionStorage.getItem("ttMetaTracking");
        var eventCategory = getEventCategoryForExperienceId(experienceId);
        if (!ttMetaTrackingActivityIdString) {
            storeActivityIdInSessionStorage([], activityId);
            trackEvent(ttMetaTrackingEvent(activityId, eventCategory));
            return;
        }
        var ttMetaTrackingActivityIds = JSON.parse(ttMetaTrackingActivityIdString);
        var activityIdIsStored = ttMetaTrackingActivityIds.includes(activityId);
        if (!activityIdIsStored) {
            storeActivityIdInSessionStorage(ttMetaTrackingActivityIds, activityId);
            trackEvent(ttMetaTrackingEvent(activityId, eventCategory));
            return;
        }
        return;
    };
    useEffect(function() {
        function handle() {
            return _handle.apply(this, arguments);
        }
        function _handle() {
            _handle = _async_to_generator(function() {
                var ref, activityData, error;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            _state.trys.push([
                                0,
                                2,
                                ,
                                3
                            ]);
                            return [
                                4,
                                timeoutPromise(targetTimeout, ttMetaMonitor())
                            ];
                        case 1:
                            _state.sent();
                            if ((ref = getTargetPropertyFromWindow()) === null || ref === void 0 ? void 0 : ref.length) {
                                activityData = getTargetActivity(activityId);
                                if (!activityData) {
                                    setActivityDataIfMounted(null);
                                    console.warn("\uD83E\uDDEA Experiment ".concat(activityConst, " - ").concat(activityId, " not found"));
                                    return [
                                        2
                                    ];
                                }
                                setActivityDataIfMounted(activityData);
                                if (shouldFlagActivity) handleTtMetaTracking(activityId, activityData.ExperienceId);
                                return [
                                    2
                                ];
                            }
                            setActivityDataIfMounted(null);
                            return [
                                3,
                                3
                            ];
                        case 2:
                            error = _state.sent();
                            console.warn("\uD83E\uDDEA Error while retrieving experiments - ".concat(error));
                            setActivityDataIfMounted(null);
                            return [
                                3,
                                3
                            ];
                        case 3:
                            return [
                                2
                            ];
                    }
                });
            });
            return _handle.apply(this, arguments);
        }
        handle();
        return function() {
            didCancel.current = true;
        };
    }, [
        activityConst,
        targetTimeout
    ]);
    return activityData;
}
function ttMetaTrackingEvent(activityId, categoryName) {
    return {
        eventName: "ttMETA",
        type: TrackingEventTypes.ADOBE,
        data: {
            event: {
                name: EventNames.click,
                category: categoryName,
                value: activityId
            }
        }
    };
}
/**
 * Monitors window.ttMeta for the adobe target property (using getTargetPropertyFromWindow) and resolves the promise when the property is available.
 * @returns A promise that resolves when the property is available.
 */ function ttMetaMonitor() {
    return new Promise(function(resolve) {
        var interval = setInterval(function() {
            if (window["ttMETA"] !== undefined) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
}
/**
 * Retrieves the Adobe Target property from the window object.
 * @returns An array of TargetResponseToken objects or undefined if the window object is not available.
 */ function getTargetPropertyFromWindow() {
    return "object" === "undefined" ? undefined : window["ttMETA"];
}
/**
 * Retrieves the target activity with the specified activity ID.
 * @param activityId The ID of the target activity.
 * @returns The target activity object if found, or null if not found.
 */ function getTargetActivity(activityId) {
    var ref;
    var targetActivity = (ref = getTargetPropertyFromWindow()) === null || ref === void 0 ? void 0 : ref.find(function(activity) {
        return activity.ActivityId === activityId;
    });
    if (targetActivity) {
        var ActivityName = targetActivity.ActivityName, ExperienceName = targetActivity.ExperienceName;
        datadogRum.addFeatureFlagEvaluation(transformActivityNameForDatadog(ActivityName), ExperienceName);
    }
    return targetActivity !== null && targetActivity !== void 0 ? targetActivity : null;
}
function transformActivityNameForDatadog(activityName) {
    // parse out sections of the standard activity name format
    // e.g. CI168 - Search - Multiple Image Gallery - DEV/LOCAL
    var matchResult = RegExp("(?<experimentCode>.+)\\s-\\s(?<squad>.+)\\s-\\s(?<experimentName>.+)\\s-\\s(?<environment>.+)", "g").exec(activityName);
    if (!(matchResult === null || matchResult === void 0 ? void 0 : matchResult.groups)) {
        return "experiment_".concat(sanitiseActivityNameForDatadogRUM(activityName));
    }
    var _groups = matchResult.groups, experimentCode = _groups.experimentCode, squad = _groups.squad, experimentName = _groups.experimentName;
    return sanitiseActivityNameForDatadogRUM("experiment_".concat(squad, "_").concat(experimentCode, "_").concat(experimentName.replace(/ /g, "_")));
}
function sanitiseActivityNameForDatadogRUM(activityName) {
    var charactersToReplace = [
        [
            /\./g,
            "_"
        ],
        [
            /:/g,
            "_"
        ],
        [
            /\+/g,
            "_"
        ],
        [
            /-/g,
            "_"
        ],
        [
            /=/g,
            "_"
        ],
        [
            /&&/g,
            "_AND_"
        ],
        [
            /\|\|/g,
            "_OR_"
        ],
        [
            />/g,
            "_greater_than_"
        ],
        [
            /</g,
            "_less_than_"
        ],
        [
            /!/g,
            ""
        ],
        [
            /\(/g,
            ""
        ],
        [
            /\)/g,
            ""
        ],
        [
            /{/g,
            ""
        ],
        [
            /}/g,
            ""
        ],
        [
            /\[/g,
            ""
        ],
        [
            /]/g,
            ""
        ],
        [
            /\^/g,
            ""
        ],
        [
            /"/g,
            "'"
        ],
        [
            /“/g,
            "'"
        ],
        [
            /”/g,
            "'"
        ],
        [
            /~/g,
            "_"
        ],
        [
            /\*/g,
            ""
        ],
        [
            /\?/g,
            ""
        ],
        [
            /\\/g,
            "_"
        ],
        [
            / /g,
            "_"
        ], 
    ];
    return charactersToReplace.reduce(function(sanitisedActivityName, param) {
        var _param = _sliced_to_array(param, 2), characterToReplace = _param[0], replacementCharacter = _param[1];
        return sanitisedActivityName.replace(characterToReplace, replacementCharacter);
    }, activityName);
}
function getEventCategoryForExperienceId(experienceId) {
    switch(experienceId){
        case "0":
            return "ttMETAControl";
        case "1":
            return "ttMETAVariant";
        // if it's not 0 or 1, we're in a multivariate test with multiple variants, so append the experienceId
        default:
            return "ttMETAVariant-".concat(experienceId);
    }
}
export default useAdobeTarget;
