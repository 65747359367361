import _define_property from "@swc/helpers/src/_define_property.mjs";
import { isBrowser } from "./is-browser";
export var PaymentDecision;
(function(PaymentDecision) {
    PaymentDecision["PAY_IN_FULL"] = "PAY_IN_FULL";
    PaymentDecision["PAY_WITH_FINANCE"] = "PAY_WITH_FINANCE";
    PaymentDecision["NONE"] = "NONE";
})(PaymentDecision || (PaymentDecision = {}));
export var paymentDecisionInStorage = "paymentDecision";
export var getPaymentDecisionFromLocalStorage = function() {
    if ("object" === "undefined") {
        return null;
    }
    return window.localStorage.getItem(paymentDecisionInStorage);
};
export var cardDetailsPagePath = "/checkout/card-details/";
export var deliveryPagePath = "/checkout/delivery/";
export var personalDetailsPagePath = "/checkout/personal-details/";
export var findVehiclePath = "/find-vehicle/";
export var partExchangePagePath = "/checkout/part-exchange/";
export var financePagePath = getPaymentDecisionFromLocalStorage() === PaymentDecision.PAY_WITH_FINANCE ? "/checkout/finance/" : "/checkout/payment/";
export var cinchCarePagePath = "checkout/cinch-care/";
export var extrasPagePath = "/checkout/car-care/";
export var cinchCoverPagePath = "/checkout/cinch-cover/";
export var CheckoutStage;
(function(CheckoutStage) {
    CheckoutStage["PersonalDetails"] = "PersonalDetails";
    CheckoutStage["PartExchange"] = "PartExchange";
    CheckoutStage["Finance"] = "Finance";
    CheckoutStage["DeliverySlots"] = "DeliverySlots";
    CheckoutStage["Extras"] = "Extras";
    CheckoutStage["CinchCare"] = "CinchCare";
    CheckoutStage["CinchCover"] = "CinchCover";
    CheckoutStage["Payment"] = "Payment";
    CheckoutStage["SignUp"] = "SignUp";
})(CheckoutStage || (CheckoutStage = {}));
export var returnToCheckoutStage = function(checkoutStep, activeOrder) {
    var vehicle = activeOrder && activeOrder.vehicle;
    var orderId = activeOrder && activeOrder.id;
    var _obj;
    var stages = (_obj = {}, _define_property(_obj, CheckoutStage.PartExchange, partExchangePagePath), _define_property(_obj, CheckoutStage.Finance, financePagePath), _define_property(_obj, CheckoutStage.DeliverySlots, deliveryPagePath), _define_property(_obj, CheckoutStage.Extras, extrasPagePath), _define_property(_obj, CheckoutStage.CinchCare, cinchCarePagePath), _define_property(_obj, CheckoutStage.CinchCover, cinchCoverPagePath), _define_property(_obj, CheckoutStage.Payment, cardDetailsPagePath), _obj);
    if (stages[checkoutStep]) {
        return window.location.assign("".concat(stages[checkoutStep]).concat(orderId));
    }
    if (vehicle) {
        return window.location.assign("".concat(personalDetailsPagePath).concat(vehicle.id));
    }
    return;
};
var isOrderCheckoutStage = function(maybeCheckoutStage) {
    return Object.prototype.hasOwnProperty.call(maybeCheckoutStage, "orderId") && Object.prototype.hasOwnProperty.call(maybeCheckoutStage, "stage");
};
export var getLastCheckoutStage = function(activeOrder) {
    var ref;
    var storageLastCheckoutStage = isBrowser ? JSON.parse((ref = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem("checkout-last-visited-stage")) !== null && ref !== void 0 ? ref : "{}") : null;
    if (!activeOrder) {
        return;
    }
    if (storageLastCheckoutStage && isOrderCheckoutStage(storageLastCheckoutStage) && storageLastCheckoutStage.orderId === (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.id)) {
        return storageLastCheckoutStage;
    }
    return {
        orderId: activeOrder.id,
        stage: CheckoutStage.PersonalDetails
    };
};
export var checkoutTimes = function(activeOrder) {
    var warningTime = 5 * 60 * 1000;
    var timeNow = Math.floor(Date.now());
    var timeRemainingInSeconds = activeOrder ? Math.floor(((activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) - timeNow) / 1000) : 0;
    var isExpiringSoon = (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) ? warningTime + timeNow > (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder.expiresAt) : null;
    return {
        timeRemainingInSeconds: timeRemainingInSeconds,
        isExpiringSoon: isExpiringSoon
    };
};
